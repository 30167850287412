<template>
  <b-card no-body class="p-sm-0 pl-0 pr-0">
    <b-alert show :variant="colorVariant('')">
      <div class="alert-body">
        <feather-icon class="mr-25" size="18" icon="AlertCircleIcon" />
        <span class="ml-25" v-html="$t('system.account_setting.change_password.alert')"></span>
      </div>
    </b-alert>

    <!-- form -->
    <validation-observer ref="changePasswordForm" #default="{invalid}">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col lg="6">
            <b-form-group :description="$t('system.account_setting.change_password.password_old_description')" label-for="account-old-password">
              <validation-provider #default="{ errors }" :name="$t('system.account_setting.change_password.password_old')"
                vid="password_old" rules="required|min:6">
              <b-input-group class="input-group-merge">
                <b-form-input id="account-old-password" v-model="form.password_old" :name="$t('system.account_setting.change_password.password_old')" :type="passwordFieldTypeOld" :placeholder="$t('system.account_setting.change_password.password_old')" />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col lg="6">
            <b-form-group :description="$t('system.account_setting.change_password.password_new_description')" label-for="account-new-password">
              <validation-provider #default="{ errors }" :name="$t('system.account_setting.change_password.password_new')" vid="password_new"
                rules="required|min:6">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-password" v-model="form.password" :type="passwordFieldTypeNew"
                    :name="$t('system.account_setting.change_password.password_new')" :placeholder="$t('system.account_setting.change_password.password_new')" maxlength="15" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePassword" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col lg="6">
            <b-form-group :description="$t('system.account_setting.change_password.password_confirmation_description')" label-for="account-retype-new-password">
              <validation-provider #default="{ errors }" :name="$t('common.password_confirmation')" vid="repeat_password"
                rules="required|confirmed:password_new">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input id="account-retype-new-password" v-model="form.repeat" :type="passwordFieldTypeRetype"
                    :name="$t('system.account_setting.change_password.password_confirmation')" :placeholder="$t('common.password_confirmation')" maxlength="15" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" :disabled="invalid || formSubmitted" @click="preSubmit">
              <font-awesome-icon :icon="['fas', 'check']" v-if="!formSubmitted" class="mr-50" />
              <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
              {{ $t('common.save') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" class="mt-1" @click="resetForm">
              <font-awesome-icon :icon="['fas', 'times']" class="mr-50" />
              {{ $t('common.reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Crypt from '@core-md/utils/crypt'
import useJwt from '@core-md/services/auth/useJwt'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [
    handleAxiosException,
    variants,
  ],
  data() {
    return {
      form: {
        password_old: '',
        password: '',
        repeat: '',
      },
      formSubmitted: false,
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      // validation
      required,
    }
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePassword() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    preSubmit() {
      this.submitForm()
    },
    submitForm() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const token = Crypt.signToken(this.form)
          const request = token
          useJwt.changePassword({
            request,
          })
            .then(response => {
              const result = Crypt.decrypt(response.data.result)

              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
                this.$router.push({ name: 'auth-logout' })
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('system.account_setting.change_password.messages.error'))
              }
              this.formSubmitted = false
            })
            .catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'system.account_setting.change_password.messages.error')
            })
        }
      })
    },
    resetForm() {
      this.form = {
        password_old: '',
        password: '',
        repeat: '',
      }
      this.$refs.changePasswordForm.reset()
    },
  },
}
</script>
