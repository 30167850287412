var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-sm-0 pl-0 pr-0",attrs:{"no-body":""}},[_c('b-alert',{attrs:{"show":"","variant":_vm.colorVariant('')}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"size":"18","icon":"AlertCircleIcon"}}),_c('span',{staticClass:"ml-25",domProps:{"innerHTML":_vm._s(_vm.$t('system.account_setting.change_password.alert'))}})],1)]),_c('validation-observer',{ref:"changePasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('system.account_setting.change_password.password_old_description'),"label-for":"account-old-password"}},[_c('validation-provider',{attrs:{"name":_vm.$t('system.account_setting.change_password.password_old'),"vid":"password_old","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":_vm.$t('system.account_setting.change_password.password_old'),"type":_vm.passwordFieldTypeOld,"placeholder":_vm.$t('system.account_setting.change_password.password_old')},model:{value:(_vm.form.password_old),callback:function ($$v) {_vm.$set(_vm.form, "password_old", $$v)},expression:"form.password_old"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('system.account_setting.change_password.password_new_description'),"label-for":"account-new-password"}},[_c('validation-provider',{attrs:{"name":_vm.$t('system.account_setting.change_password.password_new'),"vid":"password_new","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"name":_vm.$t('system.account_setting.change_password.password_new'),"placeholder":_vm.$t('system.account_setting.change_password.password_new'),"maxlength":"15"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('system.account_setting.change_password.password_confirmation_description'),"label-for":"account-retype-new-password"}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.password_confirmation'),"vid":"repeat_password","rules":"required|confirmed:password_new"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":_vm.$t('system.account_setting.change_password.password_confirmation'),"placeholder":_vm.$t('common.password_confirmation'),"maxlength":"15"},model:{value:(_vm.form.repeat),callback:function ($$v) {_vm.$set(_vm.form, "repeat", $$v)},expression:"form.repeat"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","disabled":invalid || _vm.formSubmitted},on:{"click":_vm.preSubmit}},[(!_vm.formSubmitted)?_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['fas', 'check']}}):_vm._e(),(_vm.formSubmitted)?_c('b-spinner',{staticClass:"mr-50",staticStyle:{"width":"16px","height":"16px"},attrs:{"type":_vm.spinnerType}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.resetForm}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['fas', 'times']}}),_vm._v(" "+_vm._s(_vm.$t('common.reset'))+" ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }