<template>
  <b-card no-body>
    <HTMLTitle :title="$t('system.account_setting.page_title')"></HTMLTitle>

    <card-header :title="$t('system.account_setting.page_title')" :icon="['fas', 'cog']"
      :description="$t('system.account_setting.page_info')"></card-header>

    <b-tabs content-class="mt-1 mt-md-0 p-0" class="pt-1 pl-1 pr-1 pl-sm-1 pr-sm-1" justified>

      <!-- change password tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <font-awesome-icon :icon="['fas', 'lock']" class="m-0" fixed-width />
          <span class="font-weight-bold d-none d-md-inline ml-50">{{ $t('system.account_setting.tabs.change_password') }}</span>
        </template>
        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->

      <!-- change email tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <font-awesome-icon :icon="['fas', 'envelope']" class="m-0" fixed-width />
          <span class="font-weight-bold d-none d-md-inline ml-50">{{ $t('system.account_setting.tabs.change_email') }}</span>
        </template>
        <account-setting-email />
      </b-tab>
      <!--/ change email tab -->

    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
// import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingEmail from './AccountSettingEmail.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    HTMLTitle,
    CardHeader,
    // AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingEmail,
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
