<template>
  <b-card no-body class="p-sm-0 pl-0 pr-0">
    <b-alert show :variant="colorVariant('')">
      <div class="alert-body">
        <feather-icon class="mr-25" size="18" icon="AlertCircleIcon" />
        <span class="ml-25" v-html="$t('system.account_setting.change_email.alert')"></span>
      </div>
    </b-alert>
    <!-- form -->
    <validation-observer ref="changeEmailForm" #default="{invalid}">
      <b-form>
        <b-row>
          <!-- new email -->
          <b-col lg="6">
            <b-form-group :description="$t('system.account_setting.change_email.email_new_description')"
              label-for="account-new-email">
              <validation-provider #default="{ errors }" :name="$t('system.account_setting.change_email.email_new')"
                vid="email_new" rules="required|email">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-email" v-model="form.email" :name="$t('system.account_setting.change_email.email_new')"
                    :placeholder="$t('system.account_setting.change_email.email_new')" maxlength="100" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new email -->
        </b-row>
        <b-row>
          <!-- retype email -->
          <b-col lg="6">
            <b-form-group :description="$t('system.account_setting.change_email.email_confirmation_description')" label-for="account-retype-new-email">
              <validation-provider #default="{ errors }" :name="$t('system.account_setting.change_email.email_confirmation')" vid="repeat_email"
                rules="required|confirmed:email_new">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                  <b-form-input id="account-retype-new-email" v-model="form.repeat"
                    :name="$t('system.account_setting.change_email.email_confirmation')" :placeholder="$t('system.account_setting.change_email.email_confirmation')" maxlength="100" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype email -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" :disabled="invalid || formSubmitted" @click="preSubmit">
              <font-awesome-icon :icon="['fas', 'check']" v-if="!formSubmitted" class="mr-50" />
              <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
              {{ $t('common.save') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" class="mt-1" @click="resetForm">
              <font-awesome-icon :icon="['fas', 'times']" class="mr-50" />
              {{ $t('common.reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BSpinner, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Crypt from '@core-md/utils/crypt'
import useJwt from '@core-md/services/auth/useJwt'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BSpinner,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [
    handleAxiosException,
    variants,
  ],
  data() {
    return {
      form: {
        email: '',
        repeat: '',
      },
      formSubmitted: false,
      // validation
      required,
      email,
    }
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  methods: {
    preSubmit() {
      this.submitForm()
    },
    submitForm() {
      this.$refs.changeEmailForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const token = Crypt.signToken(this.form)
          const request = token
          useJwt.changeEmail({
            request,
          })
            .then(response => {
              const result = Crypt.decrypt(response.data.result)

              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
                this.$router.push({ name: 'auth-logout' })
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('system.account_setting.change_email.messages.error'))
              }
              this.formSubmitted = false
            })
            .catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'system.account_setting.change_email.messages.error')
            })
        }
      })
    },
    resetForm() {
      this.form = {
        email: '',
        repeat: '',
      }
      this.$refs.changeEmailForm.reset()
    },
  },
}
</script>
